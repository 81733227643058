import React, { Component } from 'react';
import Main from './components/Main';
import './App.css';

class App extends Component {
  render() {
       return (
           <div>
               <Main/>
           </div>
       );
    }
}
export default App;
